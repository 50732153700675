<template>
  <p class="postcodePrefilter__text" v-html="getHtml" />
</template>

<script lang="ts">
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { IFinanceProductOption } from '~/types/finance/product'
import { useFinanceProductsStore } from '~/stores/finance/productsStore'
import { EFinanceProductsType } from '~/types/finance/products'

export default {
  setup() {
    const customerTypeStore = useCustomerTypeStore()
    const financeProductsStore = useFinanceProductsStore()

    const getDefault = (options?: IFinanceProductOption[]) => {
      const option = (options || []).find(
        (p: IFinanceProductOption) => p.IsDefault
      )
      return option?.Value || ''
    }

    const getHtml = computed(() => {
      const defaultProducts = financeProductsStore.getDefaultProduct
      const defaultProduct = defaultProducts[EFinanceProductsType.all]

      const { Name, Term, Deposit, AdvanceRentals, Mileage } = defaultProduct!

      let str = `Finance based on <strong>${Name}</strong>, with a deposit of`

      str += customerTypeStore.isBusiness
        ? ` <strong>${getDefault(
            AdvanceRentals?.Options
          )}  months</strong> initial rental`
        : ` <strong>${Deposit?.Default}</strong> of the price`

      str += `, a duration of <strong>${getDefault(
        Term?.Options
      )} months</strong>, and <strong>${formatWithCommas(
        getDefault(Mileage?.Options)
      )}  miles</strong> per year.`

      return str
    })

    return {
      getHtml
    }
  }
}
</script>

<style scoped lang="scss">
.postcodePrefilter__text {
  margin-bottom: rem(16);
  font-family: $mb-font-text;
  font-size: rem(14);
  color: $grey--darkest;
}
</style>
